<template>
  <div>
    <b-alert show variant="info" v-if="isLoading">
      <b-icon icon="arrow-counterclockwise" animation="spin-reverse"></b-icon>
      cargando datos...
    </b-alert>
    <v-card v-else-if="worksessionData" class="dailysummary">
      <v-card-title
        class="text--primary d-flex align-center justify-content-between"
      >
        <span class="text-h6">
          {{ `${this.user.name} ${this.user.surname}` }}
        </span>
        <span class="text-h5">Detalle por punto de venta</span>
        <span class="text-h6">
          {{ moment(worksessionData?.date).format("DD/MM/YYYY") }}
        </span>
      </v-card-title>
      <v-divider class="mx-4 my-0"></v-divider>
      <v-card-text class="text--primary">
        <v-row class="text-center">
          <v-col cols="12" sm="6" md="3" class="text-right">
            <span class="text-h6">Total KM</span>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-left">
            <span class="text-h6" v-if="current_km_data">
              {{ formattedCalcDifferenceStartEndKM }}
            </span>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-right">
            <span class="text-h6">Total {{ $t("Workday") }}</span>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-left">
            <span
              class="text-h6"
              v-if="diffStartEndLocalTimeOfWorksessionLabel"
            >
              {{ diffStartEndLocalTimeOfWorksessionLabel }}
            </span>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="12" sm="6" md="3" class="text-right">
            <span class="text-h6">Inicio</span>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-left">
            <span class="text-h6" v-if="worksessionData?.startLocalTime">
              {{ moment(worksessionData?.startLocalTime).format("HH:mm") }}
            </span>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-right">
            <span class="text-h6">Final</span>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="text-left">
            <span class="text-h6" v-if="worksessionData?.endLocalTime">
              {{ moment(worksessionData?.endLocalTime).format("HH:mm") }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="calendar-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="posList"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="ml-2 white--text" @click="onClickPrev">
          <b-icon icon="arrow-left"></b-icon> volver
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="ml-2 white--text"
          @click="onClickNext"
          :loading="isProcessingNext"
        >
          {{ $t("Next") }} <b-icon icon="arrow-right"></b-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import moment from "moment";

export default {
  name: "DailySummaryByPOS",
  props: ["id"],
  components: {},
  data: function() {
    return {
      isLoading: true,
      worksessionData: null,
      isProcessingNext: false,

      mainRoute: null,
      posList: [],
      totalTimeVisited: null,
      diffStartEndLocalTimeOfWorksessionLabel: null,
      current_km_data: null,
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          // if (this.filterable_col_list.includes(column.field)) {
          //   return "filterable-column " + column.field;
          // }
        },
        bodyCellClass: ({ row, column, rowIndex }) => {
          if (
            row.changedVisitType &&
            column.field === "currentVisitTypeLabel"
          ) {
            return "summary-detail-table-cell-changed-visit-type";
          }
          // if (!row.isVisited && column.field === "end_time") {
          //   return "summary-detail-table-cell-not-visited";
          // }
        }
      },
      showEmpty: false,
      defaultColumns: [
        {
          title: "",
          children: [
            {
              key: "posId",
              title: `IdPOS`,
              field: "posId",
              align: "left",
              width: 120
            },
            {
              key: "chain_name",
              title: this.$t("chain"),
              field: "chain_name",
              align: "left",
              width: 150
            },
            {
              key: "address_label",
              title: this.$t("address"),
              field: "address_label",
              align: "left",
              width: 150
            }
          ]
        },
        {
          title: "",
          children: [
            {
              key: "start_time",
              title: `Hora`,
              field: "start_time",
              align: "left",
              width: 150
            },
            {
              key: "end_time",
              title: `${this.$t("time")} ${this.$t("Visit")}`,
              field: "end_time",
              align: "left",
              width: 150
            },
            {
              key: "currentVisitTypeLabel",
              title: `${this.$t("Type")} ${this.$t("Visit")}`,
              field: "currentVisitTypeLabel",
              align: "left",
              width: 150
            }
          ]
        }
      ],
      columns: []
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("myroute", ["currentPosition", "currentGmapAddress"]),
    calcDifferenceStartEndKM() {
      let ret = 0;
      if (this.current_km_data) {
        const { startKM, endKM } = this.current_km_data;
        if (!isNaN(endKM - startKM)) {
          ret = endKM - startKM;
        }
      }
      return ret;
    },
    formattedCalcDifferenceStartEndKM() {
      return this.numberFormatEs(this.calcDifferenceStartEndKM);
    }
  },
  methods: {
    moment,
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#calendar-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    generate_new_cols(brandList, detailCols) {
      this.column = [];
      this.columns = [...this.defaultColumns];
      for (const brandItem of brandList) {
        let brand_col_push_item = {
          title: brandItem.name,
          key: `brand_col_${brandItem.id}`,
          children: []
        };
        for (const detailColItem of detailCols) {
          let date_push_item = {
            key: `brand_detail_${brandItem.id}_${detailColItem.key}`,
            title: detailColItem.label,
            field: `brand_detail_${brandItem.id}_${detailColItem.key}`,
            align: "center",
            width: 80
          };
          brand_col_push_item.children.push(date_push_item);
        }
        this.columns.push(brand_col_push_item);
      }
    },
    onClickPrev() {
      // go to daily summary route
      this.$router.push({
        name: "router_worksession_dailysummarybyroute",
        params: {
          id: this.worksessionData.id
        }
      });
    },
    async onClickNext() {
      this.$router.push({
        name: "router_worksession_dashboard"
      });
    },
    async init() {
      if (this.id) {
        this.isLoading = true;
        let form_data = {
          worksessionId: this.id
        };
        const {
          worksessionData,
          meta,
          posList,
          brandList,
          user_route,
          totalTimeVisited,
          diffStartEndLocalTimeOfWorksessionLabel,
          current_km_data
        } = await ApiService.post(
          `worksession/get_daily_summary_phrase_2`,
          form_data
        );
        this.worksessionData = worksessionData;
        this.totalTimeVisited = totalTimeVisited;
        this.diffStartEndLocalTimeOfWorksessionLabel = diffStartEndLocalTimeOfWorksessionLabel;
        this.current_km_data = current_km_data;
        this.posList = posList;
        if (user_route) {
          if (Array.isArray(user_route) && user_route.length > 0) {
            this.mainRoute = user_route[0];
          } else {
            this.mainRoute = null;
          }
        }
        let detailCols = [
          { key: "objValue", label: "Obj" },
          { key: "posBrandPSLastVisit", label: "Real" },
          { key: "diff_current_last_ps_label", label: "Vs Ult" },
          { key: "comments", label: "Com. Ult Visita" }
        ];
        this.generate_new_cols(brandList, detailCols);
        this.isLoading = false;
      }
    }
  },
  async updated() {},
  async mounted() {
    this.init();
  }
};
</script>

<style>
.dailysummary .title_section {
  background: #ddd;
  padding: 1rem;
  text-align: center;
}
.dailysummary .title_group {
  padding: 0.5rem;
  text-align: left;
  text-decoration: underline;
  margin: 0;
}
</style>
